import React from 'react';
import PropTypes from 'prop-types';
import {
  TradingAccountTransactionView,
  TransactionGOSView as TransactionGOSViewComponent,
} from '../../containers';
import { Layout } from 'antd';
import { translate } from 'react-i18next';
import { NAMESPACES } from '../../i18n';
import { TYPE_B, TYPE_H } from '../../constants/accountGOS';
import { translateTransactionTypeClassificator } from '../../utils/translateHelpers';

const TransactionGOSView = ({
  match: { params: { type, transactionId } },
  t,
}) => (
  <Layout className="main-container">
    <h1>
      {t('title')} {transactionId},{' '}
      {translateTransactionTypeClassificator(type)}
    </h1>
    {(type === TYPE_B || type === TYPE_H) && (
      <TransactionGOSViewComponent
        transactionId={transactionId}
        type={type}
        t={t}
      />
    )}
    {type !== TYPE_B &&
      type !== TYPE_H && (
        <TradingAccountTransactionView
          transactionId={transactionId}
          type={type}
          t={t}
        />
      )}
  </Layout>
);

TransactionGOSView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      transactionId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate(NAMESPACES.transactionGOSView)(TransactionGOSView);
