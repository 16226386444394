import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import {
  SearchLegalEntityConsumers,
  CancelingCertificatesGoAmount,
  PerformCertificatesTable,
  CancelingAvailabilityWarning,
} from '../../components';
import { Form, Spin, Button, Row, Col } from 'antd';
import { goToConsumptionPoint } from '../../utils/gotoLink';
import { Select } from '../../atoms';
import * as actions from '../../actions/actionCreators/cancelingCertificatesActionCreators';
import * as selectors from '../../reducers/cancelingCertificatesReducer';
import { meteringPointFields } from '../../constants/meteringPoint';
import classifiersStore, {
  mapPropertiesToObject,
} from '../../utils/classifiersStore';
import { classifierConsumptionUsageProperties } from '../../constants/classifier';

const FormItem = Form.Item;

const ALL_TRANSPORT = 'ALL_TRANSPORT';
const ALL = 'ALL';

export class CancelingCertificates extends Component {
  static propTypes = {
    pointId: PropTypes.any,
    consumerId: PropTypes.number,
    points: PropTypesImmutable.list.isRequired,
    selectPoint: PropTypes.func.isRequired,
    selectConsumer: PropTypes.func.isRequired,
    fetchConsumerPoints: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isPointsLoading: PropTypes.bool.isRequired,
    fetchGoAmount: PropTypes.func.isRequired,
    goAmounts: PropTypesImmutable.list.isRequired,
    groupedCertificates: PropTypesImmutable.list.isRequired,
    point: PropTypesImmutable.map,
    isGoLoading: PropTypes.bool.isRequired,
    isCertificatesLoading: PropTypes.bool.isRequired,
    isCancellingCertificates: PropTypes.bool.isRequired,
    selectPeriods: PropTypes.func.isRequired,
    periods: PropTypesImmutable.list.isRequired,
    cancelCertificates: PropTypes.func.isRequired,
    accountId: PropTypes.string.isRequired,
    goAmountTotal: PropTypes.number.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.props.reset();
  };

  handleSelectConsumer = value => {
    const { selectConsumer, fetchConsumerPoints } = this.props;

    fetchConsumerPoints(value);
    selectConsumer(value);
  };

  handleSelectPoint = value => {
    const {
      selectPoint,
      fetchGoAmount,
      consumerId,
      groupedCertificates,
    } = this.props;

    const input = { consumerId };

    let gasType = groupedCertificates.toJS()[0].gasType.split('_')[0];

    if (value === ALL) {
      input.all = true;
      input.gasType = gasType;
    } else if (value === ALL_TRANSPORT) {
      input.onlyTransport = true;
      input.gasType = gasType;
    } else {
      input.measurementPointId = value;
    }

    fetchGoAmount(input);
    selectPoint(value);
  };

  getAdditionalPointOptions = () => {
    const { t } = this.props;

    return [
      {
        [meteringPointFields.ID]: ALL_TRANSPORT,
        [meteringPointFields.EICZ_CODE]: t('allTransportLabel'),
      },
      {
        [meteringPointFields.ID]: ALL,
        [meteringPointFields.EICZ_CODE]: t('allPointsLabel'),
      },
    ];
  };

  getPointWarning = (point, warnings, warnTypes) => {
    const { t } = this.props;

    if (warnTypes.includes(point.get(meteringPointFields.CONSUMPTION_TYPE))) {
      return false;
    }
    warnTypes.push(point.get(meteringPointFields.CONSUMPTION_TYPE));

    const limits = mapPropertiesToObject(
      classifiersStore.getConsumptionUsageProperties(
        point.get(meteringPointFields.CONSUMPTION_TYPE)
      )
    );

    const from = parseInt(
      limits[classifierConsumptionUsageProperties.START_DAY] || 0,
      10
    );
    const to = parseInt(
      limits[classifierConsumptionUsageProperties.END_DAY] || 0,
      10
    );
    const currentDay = new Date().getDate();

    warnings.push(<CancelingAvailabilityWarning t={t} from={from} to={to} />);
    return currentDay >= from && currentDay <= to;
  };

  render() {
    const {
      pointId,
      consumerId,
      points,
      t,
      isPointsLoading,
      goAmounts,
      isGoLoading,
      isCancellingCertificates,
      groupedCertificates,
      selectPeriods,
      periods,
      isCertificatesLoading,
      accountId,
      cancelCertificates,
      goAmountTotal,
      point,
    } = this.props;

    if (!isCertificatesLoading && groupedCertificates.isEmpty()) {
      return t('groupedEmpty');
    }

    const warnings = [];
    const warnTypes = [];
    let isCancelAvailable = false;
    if (point && !point.isEmpty()) {
      isCancelAvailable =
        isCancelAvailable || this.getPointWarning(point, warnings, warnTypes);
    }
    if (pointId === ALL || pointId === ALL_TRANSPORT) {
      isCancelAvailable = true;
    }
    let gasType = groupedCertificates.toJS()[0].gasType.split('_')[0];
    return (
      <React.Fragment>
        {warnings}
        <Spin spinning={isCancellingCertificates}>
          <FormItem label={t('consumer')}>
            <Row gutter={8}>
              <Col span={20}>
                <SearchLegalEntityConsumers
                  value={consumerId}
                  onChange={this.handleSelectConsumer}
                />
              </Col>
              <Col span={4}>
                <Button onClick={goToConsumptionPoint}>
                  {t('addPointButton')}
                </Button>
              </Col>
            </Row>
          </FormItem>
          {consumerId && (
            // isCancellingCertificates - to avoid multiple spinners on the same page
            <Spin spinning={isPointsLoading && !isCancellingCertificates}>
              <FormItem label={t('point')}>
                <Select
                  value={pointId}
                  valueKey={meteringPointFields.ID}
                  labelKey={meteringPointFields.EICZ_CODE}
                  onChange={this.handleSelectPoint}
                  options={this.getAdditionalPointOptions().concat(
                    points
                      .toJS()
                      .filter(
                        e =>
                          e.gasType === gasType ||
                          (e.gasType === 'LIQUID_BIOMETHANE' &&
                            gasType === 'BIOMETHANE')
                      )
                  )}
                />
              </FormItem>
            </Spin>
          )}
          {pointId && (
            // isCancellingCertificates - to avoid multiple spinners on the same page
            <Spin
              spinning={
                (isGoLoading || isCertificatesLoading) &&
                !isCancellingCertificates
              }
            >
              <CancelingCertificatesGoAmount
                t={t}
                goAmounts={goAmounts.toJS()}
                periods={periods.toJS()}
                selectPeriods={selectPeriods}
                goAmountTotal={goAmountTotal}
              />
              <h2 className="global-margin-top-24">{t('defineGO')}</h2>
              <PerformCertificatesTable
                dataList={groupedCertificates.toJS()}
                goAmountTotal={goAmountTotal}
                isCanceling
                isCancelAvailable={isCancelAvailable}
                performAction={dataList =>
                  cancelCertificates({
                    meteringPointId: pointId,
                    periods: periods.toJS(),
                    currentAccountId: accountId,
                    consumerId,
                    dataList: dataList,
                  })
                }
              />
            </Spin>
          )}
        </Spin>
      </React.Fragment>
    );
  }
}

const getPointsLoading = getLoadingSelector(selectors.getPoints);
const getGosLoading = getLoadingSelector(selectors.getGoAmount);
const getCertificatesLoading = getLoadingSelector(selectors.getCertificates);
const getIsCancellingCertificatesLoading = getLoadingSelector(
  selectors.getIsCancellingCertificates
);

const mapStateToProps = state => ({
  consumerId: selectors.getConsumerId(state),
  pointId: selectors.getPointId(state),
  point: selectors.getSelectedPoint(state),
  points: selectors.getPointsData(state),
  isPointsLoading: getPointsLoading(state),
  isGoLoading: getGosLoading(state),
  isCertificatesLoading: getCertificatesLoading(state),
  isCancellingCertificates: getIsCancellingCertificatesLoading(state),
  goAmounts: selectors.getGoAmountData(state),
  groupedCertificates: selectors.getCertificatesData(state),
  periods: selectors.getPeriods(state),
  goAmountTotal: selectors.getGoAmountTotal(state),
});

const mapDispatchToProps = {
  selectConsumer: actions.selectConsumer,
  selectPoint: actions.selectPoint,
  fetchConsumerPoints: actions.fetchConsumerPoints,
  fetchGoAmount: actions.fetchGoAmount,
  selectPeriods: actions.selectPeriods,
  cancelCertificates: actions.cancelCertificates,
  reset: actions.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  CancelingCertificates
);
