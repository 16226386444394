export const meteringPointFields = {
  ID: 'id',
  PRODUCTION_UNIT_ID: 'productionUnitId',
  EICZ_CODE: 'eiczCode',
  DATA_SOURCE: 'dataSource',
  GRID_OPERATOR: 'gridOperator',
  COUNTY: 'county',
  CITY: 'municipality',
  DISTRICT: 'district',
  ADDRESS: 'address',
  ZIP_CODE: 'zipCode',
  START_DATE: 'startDate',
  STATUS: 'status',
  END_DATE: 'endDate',
  AUTOMATIC_CANCELLATION: 'automaticCancellation',
  GAS: 'gasType',
  CONSUMPTION_TYPE: 'consumptionType',
  METERING_POINT_TYPE: 'measuringPointType',
  CONSUMER: 'consumer',
  CONSUMER_ID: 'consumerId',
  PRODUCTION_UNIT: 'productionUnit',
  NAME: 'name',
  PERSONAL_CODE: 'personalCode',
  REGISTRATION_CODE: 'registrationCode',
  CONSUMER_TYPE: 'consumerType',
  LEGAL_ENTITY: 'legalEntity',
  LEGAL_ENTITY_ID: 'legalEntityId',
};
export const gas = {
  HYDROGEN: 'HYDROGEN',
  BIOMETHANE: 'BIOMETHANE',
  LIQUID_BIOMETHANE: 'LIQUID_BIOMETHANE',
};

export const meteringPointDataFields = {
  MONTH: 'month',
  CONSUMED_AMOUNT_KWH: 'consumedAmount',
  CONSUMED_AMOUNT_M: 'consumedVolume',
  PRODUCED_AMOUNT_KWH: 'producedAmount',
  PRODUCED_SALDO: 'saldo',
  AMOUNT_BY_MONTH: 'amountByMonth',
  PRODUCED_AMOUNT_M: 'producedVolume',
  CONFIRMED: 'confirmed',
  GO: 'go',
  RECEIVED_SUPPORT: 'receivedSupport',
};

export const electricMeteringPointSearchFields = {
  ID: 'id',
  EICZ_CODE: 'eicZ',
  CUSTOMER: 'consumer',
  LOADING_OPERATOR: 'loadingOperator',
  STATUS: 'status',
};

export const electricMeteringPointDataFields = {
  MONTH: 'month',
  CONSUMED_AMOUNT_MWH: 'consumedAmount',
  CURRENT_SALDO: 'currentSaldo',
  NATURAL_QUANTITY_MJ: 'naturalQuantity',
  ACCOUNTING_QUANTITY_MJ: 'accountingQuantity',
  AMOUNT_BY_MONTH: 'amountByMonth',
};

export const electricIssuesTSCertificatesAmountsDataFields = {
  MONTH: 'month',
  DIRECT_LINE_NATURAL_CONSUMPTION: 'directLineNaturalConsumption',
  DIRECT_LINE_ACCOUNTING_CONSUMPTION: 'directLineAccountingConsumption',
  NON_DIRECT_LINE_NATURAL_CONSUMPTION: 'nonDirectLineNaturalConsumption',
  NON_DIRECT_LINE_ACCOUNTING_CONSUMPTION: 'nonDirectLineAccountingConsumption',
  NATURAL_QUANTITY_MJ: 'naturalQuantity',
  ACCOUNTING_QUANTITY_MJ: 'accountingQuantity',
};
