import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form/immutable';
import { Row, Col } from 'antd';
import { List } from 'immutable';
import { get } from 'lodash';
import { connect } from 'react-redux';
import PropTypesImmutable from 'react-immutable-proptypes';
import {
  FORM_DOCUMENTS,
  documentsFormFields,
} from '../../constants/formsAddEquipment';
import { RegisterActions, FileUpload } from '../../components';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import * as documentActions from '../../actions/actionCreators/productionUnitDocumentsActionCreators';
import * as documentSelectors from '../../reducers/productionUnitDocumentsReducer';
import { getProductionUnitDocuments } from '../../schemas/productionUnitDocument';
import { FILE_URL } from '../../constants/file';

const mapDocumentsToFiles = documents =>
  documents.toJS().map(d => ({
    uid: get(d, documentsFormFields.ID),
    name: get(d, documentsFormFields.NAME),
    status: 'done',
    url: `${FILE_URL}/${get(d, documentsFormFields.FILE)}`,
  }));

export class AddEquipmentDocuments extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hasPrevious: PropTypes.bool,
    hasDone: PropTypes.bool,
    goPrevious: PropTypes.func,
    fetchProductionUnitDocuments: PropTypes.func.isRequired,
    addProductionUnitDocument: PropTypes.func.isRequired,
    deleteProductionUnitDocument: PropTypes.func.isRequired,
    isFilesLoading: PropTypes.bool.isRequired,
    documents: PropTypesImmutable.list,
    productionUnit: PropTypesImmutable.map.isRequired,
    inEditForNotAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    showRequestChangeModal: PropTypes.func,
  };

  static defaultProps = {
    documents: List(),
  };

  componentDidMount = () => {
    const { fetchProductionUnitDocuments, productionUnit } = this.props;

    fetchProductionUnitDocuments(productionUnit.get('id'));
  };

  onFileChange = info => {
    const {
      addProductionUnitDocument,
      initialValues,
      deleteProductionUnitDocument,
    } = this.props;

    if (info.file.status === 'done') {
      addProductionUnitDocument({
        [documentsFormFields.FILE]: info.file.response,
        [documentsFormFields.PRODUCTION_UNIT_ID]: initialValues.get('id'),
        [documentsFormFields.NAME]: info.file.name,
      });
    } else if (info.file.status === 'removed') {
      deleteProductionUnitDocument(info.file.uid);
    }
  };

  render() {
    const {
      handleSubmit,
      hasPrevious,
      hasDone,
      goPrevious,
      t,
      documents,
      isFilesLoading,
      inEditForNotAdmin,
      isAdmin,
      isEditMode,
      showRequestChangeModal,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <h2>{isEditMode ? t('documents.titleEdit') : t('documents.title')}</h2>
        <Row gutter={32}>
          <Col className="gutter-row" span={12}>
            <h3>{t('documents.fileUpload')}</h3>
            {!isFilesLoading && (
              <FileUpload
                t={t}
                onChange={this.onFileChange}
                defaultFileList={mapDocumentsToFiles(documents)}
                showUploadList={{ showRemoveIcon: !inEditForNotAdmin }}
                disabled={inEditForNotAdmin}
              />
            )}
          </Col>
        </Row>
        <RegisterActions
          showRequestChangeModal={showRequestChangeModal}
          hasPrevious={hasPrevious}
          hasDone={hasDone}
          goPrevious={goPrevious}
          isEditMode={isEditMode}
          showSaveButton={!isEditMode}
          isAdmin={isAdmin}
          t={t}
        />
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFilesLoading: getLoadingSelector(
    documentSelectors.getProductionUnitDocumentsAddState,
    documentSelectors.getProductionUnitDocumentsList
  )(state),
  documents: getProductionUnitDocuments(
    state,
    documentSelectors.getProductionUnitDocumentsList(state)
  ),
  initialValues: ownProps.productionUnit,
});

const mapDispatchToProps = {
  ...documentActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_DOCUMENTS,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: () => {},
  })(AddEquipmentDocuments)
);
