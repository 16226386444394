export const accountGOSFields = {
  ID: 'id',
  ID_LIST: 'idList',
  IDS: 'ids',
  EXPIRATION_DATE: 'expirationDate',
  CALCULATED_ENERGY_AMOUNT: 'calculatedEnergyAmount',
  NATURAL_ENERGY_AMOUNT: 'naturalEnergyAmount',
  GHG_AMOUNT: 'ghgAmount',
  OWNER_LEGAL_ENTITY_ID: 'ownerLegalEntityId',
  CONSUMPTION_YEAR: 'consumptionYear',
  TYPE: 'type',
};

export const TYPE_B = 'B';
export const TYPE_H = 'H';
export const TYPE_E = 'E';
export const TYPE_S = 'S';
export const TYPE_KHG = 'KHG';

export const tradingAccountTransactionsFields = {
  ID: 'id',
  TRANSACTION_ID: 'transactionId',
  TYPE: 'type',
  TRANSACTION_TYPE: 'transactionType',
  QUANTITY: 'quantity',
  SENDER: 'sender',
  RECEIVER: 'receiver',
  SENDER_NUMBER: 'senderNumber',
  RECEIVER_NUMBER: 'receiverNumber',
  TRANSACTION_DATE: 'transactionDate',
  TRANSACTION_DATE_TIME: 'transactionDateTime',
  TRANSACTION_STATUS: 'transactionStatus',
  TRANSACTION_AMOUNT_MJ: 'transactionAmountMj',
  ACTION: 'action',
};

export const accountGOSReportFields = {
  ID: 'id',
  PERIOD: 'period',
  ISSUED_GOS: 'issuedGOS',
  OUTGOING_GOS: 'outgoingGOS',
  INCOMING_GOS: 'incomingGOS',
  EXPIRATION_GOS: 'expirationGOS',
  TRANSACTION_DATE: 'transactionDate',
  LOCKED_GOS: 'lockedGOS',
};

export const accountGOSAgreementFields = {
  ID: 'id',
  status: 'status',
  queueNo: 'queueNo',
  counterPartyName: 'counterPartyName',
  finalConsumptionYear: 'finalConsumptionYear',
  fuelType: 'fuelType',
  fuelGeneration: 'fuelGeneration',
  ghgCapacity: 'ghgCapacity',
  agreementType: 'agreementType',
  naturalEnergyAmount: 'naturalAmountMj',
  calculatedEnergyAmount: 'calculatedAmountMj',
  fulfilledPercentage: 'fulfilledPercentage',
  actions: 'actions',
};

export const accountGOSTsAssignmentFields = {
  ID: 'id',
  state: 'state',
  type: 'type',
  tradingMethod: 'tradingMethod',
  counterparty: 'counterparty',
  dueDate: 'dueDate',
  actions: 'actions',
};
