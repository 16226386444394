import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { TextField, ClassificatorField, SelectField } from '../../atoms';
import {
  FormActions,
  AutoCompleteConsumersField,
  AutoCompleteCompaniesField,
  SearchLegalEntityConsumersField,
} from '../../components';
import { connect } from 'react-redux';
import {
  meteringPointFilterFields,
  METERING_POINT_FILTER_FORM,
} from '../../constants/formConsumptionPointFilter';
import { Collapse, Row, Col, Button } from 'antd';
import {
  getRole,
  getIsUserProducer,
  getIsUserSeller,
} from '../../reducers/userReducer';
import { isAdmin } from '../../utils/roles';
import * as actions from '../../actions/actionCreators/consumptionPointListActionCreators';
import { gas } from '../../constants/meteringPoint';

const { Panel } = Collapse;

export class MeteringPointFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    userRole: PropTypes.string.isRequired,
    isUserProducer: PropTypes.bool.isRequired,
    isUserSeller: PropTypes.bool.isRequired,
  };

  onClear = () => {
    const { submit, reset } = this.props;

    reset();
    submit();
  };

  render() {
    const {
      t,
      handleSubmit,
      userRole,
      isUserProducer,
      isUserSeller,
    } = this.props;

    const isUserAdmin = isAdmin(userRole);
    return (
      <Collapse>
        <Panel key="filters" header={t('filter.title')}>
          <form onSubmit={handleSubmit}>
            <Row gutter={32}>
              {(isUserProducer || isUserAdmin) && (
                <Col span={8}>
                  <Field
                    label={t('filter.type')}
                    name={meteringPointFilterFields.TYPE}
                    component={ClassificatorField}
                    type={ClassificatorField.types.meteringPointType}
                    hasFeedback={false}
                  />
                </Col>
              )}
              <Col span={8}>
                <Field
                  label={t('filter.dataSource')}
                  name={meteringPointFilterFields.DATA_SOURCE}
                  component={ClassificatorField}
                  type={ClassificatorField.types.meteringPointDataSource}
                  hasFeedback={false}
                />
              </Col>
              <Col span={8}>
                <Field
                  label={t('filter.gas.title')}
                  name={meteringPointFilterFields.GAS}
                  component={SelectField}
                  options={[
                    {
                      value: gas.HYDROGEN,
                      label: t('filter.gas.hydrogen'),
                    },
                    {
                      value: gas.BIOMETHANE,
                      label: t('filter.gas.biomethane'),
                    },
                    {
                      value: gas.LIQUID_BIOMETHANE,
                      label: t('filter.gas.liquidBiomethane'),
                    },
                  ]}
                  hasFeedback={false}
                />
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={8}>
                <Field
                  label={t('filter.eicz')}
                  name={meteringPointFilterFields.EICZ_CODE}
                  component={TextField}
                  hasFeedback={false}
                />
              </Col>
              {isUserAdmin && (
                <Col span={8}>
                  <Field
                    label={t('filter.owner')}
                    name={meteringPointFilterFields.OWNER_ID}
                    component={AutoCompleteCompaniesField}
                    hasFeedback={false}
                  />
                </Col>
              )}
              <Col span={8}>
                <Field
                  label={t('filter.consumer')}
                  name={meteringPointFilterFields.CONSUMER_ID}
                  component={
                    isUserSeller && !isUserAdmin
                      ? SearchLegalEntityConsumersField
                      : AutoCompleteConsumersField
                  }
                  hasFeedback={false}
                />
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={8}>
                <Field
                  label={t('filter.status')}
                  name={meteringPointFilterFields.STATUS}
                  component={ClassificatorField}
                  type={ClassificatorField.types.meteringPointStatus}
                  hasFeedback={false}
                />
              </Col>
            </Row>
            <FormActions>
              <Button onClick={this.onClear}>{t('filter.clearBtn')}</Button>
              <Button type="primary" htmlType="submit">
                {t('filter.submitBtn')}
              </Button>
            </FormActions>
          </form>
        </Panel>
      </Collapse>
    );
  }
}

const onSubmit = (values, dispatch) =>
  dispatch(actions.searchConsumptionPoints());

const mapDispatchToProps = {
  ...actions,
};

const mapStateToProps = state => ({
  userRole: getRole(state),
  isUserProducer: getIsUserProducer(state),
  isUserSeller: getIsUserSeller(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: METERING_POINT_FILTER_FORM,
    onSubmit,
  })(MeteringPointFilter)
);
