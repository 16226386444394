import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { NAMESPACES } from '../../i18n';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { TableActions } from '../../components';
import { goBack } from '../../utils/gotoLink';
import { YearSelect } from '../../atoms';
import {
  agreementType as agreementTypeC,
  fuelType as fuelTypeC,
  tsFuelGeneration as fuelGenerationC,
} from '../../constants/classificators';
import {
  translateAgreementTypeClassificator,
  translateTsFuelGenerationClassificator,
  translateTsFuelTypeClassificator,
} from '../../utils/translateHelpers';
import { tsAgreementRegistrationTableFields as tsRegFields } from '../../constants/TsAgreementRegistrationTableFields';

class TsAgreementRegistrationTable extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fetchQueueNum: PropTypes.func.isRequired,
    performAction: PropTypes.func.isRequired,
  };

  state = {
    data: [
      {
        queueNo: null,
        fuelType: null,
        fuelGeneration: null,
        ghgCapacity: null,
        agreementType: null,
        finalConsumptionYear: null,
        naturalAmountMj: null,
        multiplier: null,
        price: null,
      },
    ],
  };

  formatNumber = (num, min = 0, max = 0) => {
    if (num === undefined || num == null) return num;
    return num
      .toLocaleString(undefined, {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
      })
      .replace(/,/g, ' ');
  };

  handleInputChange = (rowIndex, fieldName, value) => {
    const { data } = this.state;

    const newData = [...data];
    newData[rowIndex][fieldName] = value;

    if (fieldName === tsRegFields.fuelType) {
      const multipliers = this.getMultipliers(value);
      const containsNumber = multipliers.some(
        item => item.value === data[0].multiplier
      );
      if (!containsNumber) newData[rowIndex][tsRegFields.multiplier] = null;
    }

    if (
      fieldName === tsRegFields.agreementType &&
      this.isAgreementTypeEntire()
    ) {
      newData[rowIndex][tsRegFields.naturalAmountMj] = null;
    }

    if (
      fieldName === tsRegFields.fuelType &&
      this.isFuelTypeRenewableElectricity()
    ) {
      newData[rowIndex][tsRegFields.fuelGeneration] = null;
    }

    this.setState({ data: newData });

    this.handleQueueNo(fieldName, newData, rowIndex);
  };

  handleQueueNo = async (fieldName, newData, rowIndex) => {
    const { fetchQueueNum } = this.props;
    const fields = this.getFieldsForQueueNum();
    if (fields.includes(fieldName)) {
      if (this.fieldsFilledForQueueNum(fields, newData[rowIndex]))
        await fetchQueueNum(newData[rowIndex]).then(
          res => (newData[rowIndex][tsRegFields.queueNo] = res.count)
        );
      else newData[rowIndex][tsRegFields.queueNo] = null;
    }
    this.setState({ data: newData });
  };

  getFieldsForQueueNum = () => {
    return [
      tsRegFields.fuelType,
      tsRegFields.fuelGeneration,
      tsRegFields.finalConsumptionYear,
      tsRegFields.multiplier,
    ];
  };

  fieldsFilledForQueueNum = (fields, data) => {
    return fields
      .filter(
        key =>
          key !== tsRegFields.fuelGeneration ||
          data[tsRegFields.fuelType] !== fuelTypeC.RENEWABLE_ELECTRICITY
      )
      .every(key => data[key] !== null);
  };

  mapOptionsToSelect(selectValues) {
    return selectValues.map(({ value, label }) => (
      <Select.Option key={value} value={value}>
        {label}
      </Select.Option>
    ));
  }

  getMultipliers = fuelType => {
    const multipliersByFuelType = {
      [fuelTypeC.BIOMETHANE]: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
      ],
      [fuelTypeC.HYDROGEN]: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
      ],
      [fuelTypeC.RENEWABLE_ENERGY]: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 4, label: '4' },
      ],
      [fuelTypeC.RENEWABLE_ELECTRICITY]: [{ value: 4, label: '4' }],
    };

    return multipliersByFuelType[fuelType] || [];
  };

  getOptions = (optionsArray, translateFn) => {
    return optionsArray.map(option => ({
      value: option,
      label: translateFn(option),
    }));
  };

  getAgreementTypeOptions = () => {
    return this.getOptions(
      [agreementTypeC.PARTIAL, agreementTypeC.ENTIRE],
      translateAgreementTypeClassificator
    );
  };

  getFuelTypeOptions = () => {
    return this.getOptions(
      [
        fuelTypeC.BIOMETHANE,
        fuelTypeC.RENEWABLE_ENERGY,
        fuelTypeC.RENEWABLE_ELECTRICITY,
      ],
      translateTsFuelTypeClassificator
    );
  };

  getFuelGenerationOptions = () => {
    return this.getOptions(
      [
        fuelGenerationC.ADVANCED,
        fuelGenerationC.FIRST_GEN,
        fuelGenerationC.OTHER,
      ],
      translateTsFuelGenerationClassificator
    );
  };

  getHydrogenFuelGenerationOptions = () => {
    return this.getOptions(
      [
        fuelGenerationC.RFNBO_HYDROGEN,
        fuelGenerationC.OTHER_RENEWABLE_HYDROGEN,
      ],
      translateTsFuelGenerationClassificator
    );
  };

  isAgreementTypeEntire = () => {
    const { agreementType } = this.state.data[0];
    return agreementType === agreementTypeC.ENTIRE;
  };

  isFuelTypeRenewableElectricity = () => {
    const { fuelType } = this.state.data[0];
    return fuelType === fuelTypeC.RENEWABLE_ELECTRICITY;
  };

  getColumns = () => {
    const { t } = this.props;
    const { naturalAmountMj, multiplier, price, fuelType } = this.state.data[0];

    const calculatedEnergyMj = naturalAmountMj * multiplier;
    const transactionCost = calculatedEnergyMj * price * 0.001;

    const agreementTypes = this.getAgreementTypeOptions();
    const fuelTypes = this.getFuelTypeOptions();
    const fuelGenerations = this.getFuelGenerationOptions();
    const hydrogenFuelGenerations = this.getHydrogenFuelGenerationOptions();
    const multipliers = this.getMultipliers(fuelType);

    const columns = [
      {
        title: t('table.queueNo'),
        dataIndex: tsRegFields.queueNo,
      },
      {
        title: t('table.fuelType'),
        dataIndex: tsRegFields.fuelType,
        width: 130,
        render: (value, record, index) => (
          <Select
            value={value}
            style={{ width: 130 }}
            dropdownMatchSelectWidth={false}
            onChange={value => this.handleInputChange(index, 'fuelType', value)}
          >
            {this.mapOptionsToSelect(fuelTypes)}
          </Select>
        ),
      },
      {
        title: t('table.fuelGeneration'),
        dataIndex: tsRegFields.fuelGeneration,
        width: 130,
        render: (value, record, index) => (
          <Select
            value={value}
            style={{ width: 130 }}
            disabled={this.isFuelTypeRenewableElectricity()}
            dropdownMatchSelectWidth={false}
            onChange={value =>
              this.handleInputChange(index, 'fuelGeneration', value)
            }
          >
            {fuelType === 'HYDROGEN'
              ? this.mapOptionsToSelect(hydrogenFuelGenerations)
              : this.mapOptionsToSelect(fuelGenerations)}
          </Select>
        ),
      },
      {
        title: ghgTitle,
        dataIndex: tsRegFields.ghgCapacity,
        render: (value, record, index) => (
          <InputNumber
            min={0}
            step={0.1}
            precision={1}
            value={value}
            onChange={value =>
              this.handleInputChange(index, 'ghgCapacity', value)
            }
          />
        ),
      },
      {
        title: t('table.agreementType'),
        dataIndex: tsRegFields.agreementType,
        width: 130,
        render: (value, record, index) => (
          <Select
            value={value}
            style={{ width: 130 }}
            dropdownMatchSelectWidth={false}
            onChange={value =>
              this.handleInputChange(index, 'agreementType', value)
            }
          >
            {this.mapOptionsToSelect(agreementTypes)}
          </Select>
        ),
      },
      {
        title: t('table.year'),
        dataIndex: tsRegFields.finalConsumptionYear,
        width: 100,
        render: (value, record, index) => (
          <YearSelect
            yearDistance={5}
            afterCurrent
            value={value}
            style={{ width: 100 }}
            onChange={value =>
              this.handleInputChange(index, 'finalConsumptionYear', value)
            }
          />
        ),
      },
      {
        title: t('table.naturalAmountMj'),
        dataIndex: tsRegFields.naturalAmountMj,
        render: (value, record, index) => (
          <InputNumber
            disabled={this.isAgreementTypeEntire()}
            min={0}
            step={0.1}
            precision={1}
            value={value}
            onChange={value =>
              this.handleInputChange(index, 'naturalAmountMj', value)
            }
          />
        ),
      },
      {
        title: t('table.multiplier'),
        dataIndex: tsRegFields.multiplier,
        width: 130,
        render: (value, record, index) => (
          <Select
            value={value}
            style={{ width: 130 }}
            onChange={value =>
              this.handleInputChange(index, 'multiplier', value)
            }
          >
            {this.mapOptionsToSelect(multipliers)}
          </Select>
        ),
      },
      {
        title: t('table.calculatedEnergyMj'),
        dataIndex: tsRegFields.calculatedEnergyMj,
        render: () => (
          <div>
            {this.isAgreementTypeEntire()
              ? null
              : this.formatNumber(calculatedEnergyMj, 1, 1)}
          </div>
        ),
      },
      {
        title: t('table.price'),
        dataIndex: tsRegFields.price,
        render: (value, record, index) => (
          <InputNumber
            min={0}
            step={0.001}
            precision={3}
            value={value}
            onChange={value => this.handleInputChange(index, 'price', value)}
          />
        ),
      },
      {
        title: t('table.transactionCost'),
        dataIndex: tsRegFields.transactionCost,
        render: () => (
          <div>
            {this.isAgreementTypeEntire()
              ? null
              : this.formatNumber(transactionCost, 2, 2)}
          </div>
        ),
      },
    ];

    function ghgTitle() {
      return (
        <span>
          {t('table.ghgCapacity')}&nbsp;
          <Tooltip title={t('table.ghgTooltip')}>
            <Icon type="question-circle-o" />
          </Tooltip>
        </span>
      );
    }

    return columns;
  };

  handlePerformClick = async () => {
    const { t, performAction } = this.props;
    const { data } = this.state;
    const dataList = data[0];

    const isNotNull = this.validateFields(dataList);

    if (!isNotNull) {
      return message.error(t('errorMissingValues'));
    }

    const result = await performAction(dataList);

    if (!result || !result.error) {
      message.success(t('successPerform'));
      goBack();
    }
  };

  validateFields = dataList => {
    const agreementTypeEntire = this.isAgreementTypeEntire();
    const fuelTypeRenewableElectricity = this.isFuelTypeRenewableElectricity();

    return Object.entries(dataList).every(([key, value]) => {
      if (agreementTypeEntire && key === tsRegFields.naturalAmountMj)
        return true;
      if (fuelTypeRenewableElectricity && key === tsRegFields.fuelGeneration)
        return true;
      if (key === tsRegFields.ghgCapacity) return true;
      return value !== null && value !== '';
    });
  };

  render() {
    const { t } = this.props;
    const { data } = this.state;

    return (
      <React.Fragment>
        <Table
          scroll={{ x: 'auto' }}
          rowKey={tsRegFields.ID}
          dataSource={data}
          columns={this.getColumns()}
          pagination={false}
          onChange={this.handle}
        />
        <TableActions>
          <Button onClick={goBack}>{t('buttonBack')}</Button>
          <Popconfirm
            title={t('popconfirmTitleCancel')}
            onConfirm={this.handlePerformClick}
            okText={t('popconfirmButtonYes')}
            cancelText={t('popconfirmButtonNo')}
          >
            <Button type="primary" className="global-margin-left-10">
              {t('buttonPerform')}
            </Button>
          </Popconfirm>
        </TableActions>
      </React.Fragment>
    );
  }
}

export default translate(NAMESPACES.tsAgreement)(TsAgreementRegistrationTable);
