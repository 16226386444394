import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableRowActions } from '../';
import { Table, Button, Spin, Modal } from 'antd';
import {
  translateGosTransactionTypeClassificator,
  translateTransactionForGosStatusClassificator,
  translateTransactionTypeClassificator,
} from '../../utils/translateHelpers';
import { defaultDateFormater } from '../../utils';
import { tradingAccountTransactionsFields } from '../../constants/accountGOS';
import { goToTSTransactionView } from '../../utils/gotoLink';
import { hasSendVdsButton } from '../../utils/transactionHelpers';
import { VDSMessageModal } from '../../forms';

class TransactionGosTable extends Component {
  static propTypes = {
    transactions: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    userRole: PropTypes.string.isRequired,
  };

  state = {
    vdsMessageModalVisible: false,
    vdsMessageForm: {},
    transactionId: null,
    transactionType: null,
  };

  onPageChange = page => {
    const { searchTransactions } = this.props;

    searchTransactions(page);
  };

  getColumns = () => {
    const { t, userRole } = this.props;

    return [
      {
        title: t('gosTable.id'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_ID,
      },
      {
        title: t('gosTable.transactionType'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_TYPE,
        render: translateGosTransactionTypeClassificator,
      },
      {
        title: t('gosTable.type'),
        dataIndex: tradingAccountTransactionsFields.TYPE,
        render: translateTransactionTypeClassificator,
      },
      {
        title: t('gosTable.senderAccount'),
        dataIndex: tradingAccountTransactionsFields.SENDER,
      },
      {
        title: t('gosTable.senderBankAccount'),
        dataIndex: tradingAccountTransactionsFields.SENDER_NUMBER,
      },
      {
        title: t('gosTable.receiverAccount'),
        dataIndex: tradingAccountTransactionsFields.RECEIVER,
      },
      {
        title: t('gosTable.receiverBankAccount'),
        dataIndex: tradingAccountTransactionsFields.RECEIVER_NUMBER,
      },
      {
        title: t('gosTable.status'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_STATUS,
        render: translateTransactionForGosStatusClassificator,
      },
      {
        title: t('gosTable.createTime'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_DATE_TIME,
        render: defaultDateFormater,
      },
      {
        title: t('gosTable.actions'),
        dataIndex: tradingAccountTransactionsFields.TRANSACTION_ID,
        render: (value, { transactionId, type, canSendVdsRequest }) => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="select"
              size="small"
              type="primary"
              title={t('table.viewBtn')}
              onClick={() => goToTSTransactionView(type, value)}
            />
            {hasSendVdsButton(userRole, canSendVdsRequest) && (
              <React.Fragment>
                <span className="ant-divider" />
                <Button
                  shape="circle"
                  icon="retweet"
                  size="small"
                  type="danger"
                  title={t('table.sendVdsBtn')}
                  onClick={() => {
                    this.showVDSMessageModal(transactionId, type);
                  }}
                />
                <span className="ant-divider" />
              </React.Fragment>
            )}
          </TableRowActions>
        ),
        fixed: 'right',
        width: 100,
      },
    ];
  };

  showVDSMessageModal = (transactionId, transactionType) => {
    this.setState({
      transactionId: transactionId,
      transactionType: transactionType,
    });
    this.setState({ vdsMessageModalVisible: true });
  };

  hideVDSMessageModal = () => {
    this.setState({ vdsMessageModalVisible: false });
    this.setState({ transactionId: null, transactionType: null });
  };

  render() {
    const {
      t,
      transactions,
      totalElements,
      loading,
      page,
      pageSize,
      searchTransactions,
    } = this.props;
    const {
      vdsMessageModalVisible,
      transactionId,
      transactionType,
    } = this.state;

    return (
      <div>
        <Spin spinning={loading} className="search-results-container">
          <Table
            scroll={{ x: 1000 }}
            rowKey={tradingAccountTransactionsFields.ID}
            dataSource={transactions}
            columns={this.getColumns()}
            pagination={{
              onChange: this.onPageChange,
              current: page,
              total: totalElements,
              pageSize,
              defaultCurrent: 1,
            }}
          />
        </Spin>
        {vdsMessageModalVisible && (
          <Modal
            title={t('vdsMessageModalTitle')}
            visible
            footer={false}
            width="1200px"
            onCancel={this.hideVDSMessageModal}
          >
            <VDSMessageModal
              transactionId={transactionId}
              transactionType={transactionType}
              vdsMessageType={'StatisticsTransferRequest'}
              onClose={() => {
                this.hideVDSMessageModal();
                searchTransactions(page);
              }}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default TransactionGosTable;
