import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { getGosReport } from '../../reducers/reportGosReducer';
import moment from 'moment';
import { Field, Fields, getFormValues, reduxForm } from 'redux-form/immutable';
import { DateRangeField, YearSelect } from '../../atoms';
import {
  AutoCompleteProducersProviderField,
  FormActions,
} from '../../components';
import { formGosReportFields } from '../../constants/forms/formGosReport';
import { gosReportFields } from '../../constants/GosReport.js';
import { FORM_GOS_REPORT } from '../../constants/formKeys';
import { Button, Col, Form, Modal, Radio, Row, Spin, Table } from 'antd';
import { getRole } from '../../reducers/userReducer';
import { isAdmin } from '../../utils/roles';
import { FORMAT_DEFAULT_DATE } from '../../utils/dates';
import { links } from '../../utils/gotoLink';
import * as actions from '../../actions/actionCreators/reportGosActionCreators';
import TableRowActions from '../../components/TableRowActions/TableRowActions';
import {
  translateFeedstockClassificator,
  translateFuelTypeClassificator,
  translateProductionPathwayClassificator,
} from '../../utils/translateHelpers';
import './GosReport.css';

function renderYearField(field) {
  return (
    <Form.Item label={field.label}>
      <YearSelect
        {...field.input}
        onChange={value => field.input.onChange(value)}
        beforeCurrent
        afterCurrent={false}
      />
    </Form.Item>
  );
}

export class GosReport extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    formValues: PropTypesImmutable.map,
    fetchReportGosItemsPage: PropTypes.func.isRequired,
    resetGosRenewableReport: PropTypes.func.isRequired,
    gosReport: PropTypes.object,
  };

  state = {
    loading: false,
    yearFilter: true,
    detailModalVisible: false,
    gosReportDetails: {},
  };

  handleClear = () => {
    this.props.reset();
  };

  handleShowDetailsModal = details => {
    this.setState({ gosReportDetails: details });
    this.setState({ detailModalVisible: true });
  };

  hideDetailModal = () => {
    this.setState({ detailModalVisible: false });
    this.setState({ gosReportDetails: {} });
  };

  handleGenerate = async newPage => {
    const { gosReport } = this.props;
    const { formValues } = this.props;
    const { yearFilter } = this.state;
    const { fetchReportGosItemsPage } = this.props;
    const params = formValues.toJS();
    const { pageSize, page } = gosReport.toJSON();

    if (yearFilter) {
      params[formGosReportFields.DATE_FROM] = undefined;
      params[formGosReportFields.DATE_TO] = undefined;
    } else {
      params[formGosReportFields.PERIOD_YEAR] = undefined;
    }

    params[formGosReportFields.PAGE] = newPage
      ? newPage - 1
      : page ? page - 1 : 0;
    params[formGosReportFields.PAGE_SIZE] = pageSize || 20;

    // Removes unused filter values from post values
    this.setState({ loading: true });

    await fetchReportGosItemsPage(params);

    this.setState({ loading: false });
  };

  getGenerateXlsUrl() {
    const { formValues } = this.props;
    const { yearFilter } = this.state;

    const params = {
      sort: 'transactionDate',
    };

    if (formValues) {
      Object.assign(
        params,
        Object.entries(formValues.toJS()).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        )
      );

      if (yearFilter) {
        delete params[formGosReportFields.DATE_FROM];
        delete params[formGosReportFields.DATE_TO];
      } else {
        delete params[formGosReportFields.PERIOD_YEAR];
      }
    }

    const paramString = new URLSearchParams(params);
    return links.reportGosXls + '?' + paramString.toString();
  }

  renderFilterField = () => {
    const { t } = this.props;
    const { yearFilter } = this.state;
    if (yearFilter) {
      return (
        <Field
          name={formGosReportFields.PERIOD_YEAR}
          component={renderYearField}
          hasFeedback={false}
        />
      );
    }
    return (
      <Fields
        names={[formGosReportFields.DATE_FROM, formGosReportFields.DATE_TO]}
        placeholder={[
          t('gosReport.dateRange.dateFromPlaceholder'),
          t('gosReport.dateRange.dateToPlaceholder'),
        ]}
        component={DateRangeField}
        hasFeedback={false}
        useTime={false}
      />
    );
  };

  renderGosReportDetails = () => {
    const { t } = this.props;

    const rowType = this.state.gosReportDetails[
      gosReportFields.RENEWABLE_ENERGY_REPORT_ROW_TYPE
    ];
    const isB = rowType === 'B';
    const isE = rowType === 'E';
    const isH = rowType === 'H';
    const colSpan = isB ? 8 : 12;

    return (
      <div>
        <Row className="global-margin-bottom-10">
          <Col span={colSpan}>
            <div>
              <b>{t('gosReport.transactionDate')}: </b>
              {this.state.gosReportDetails[gosReportFields.TRANSACTION_DATE]}
            </div>
            <div>
              <b>{t('gosReport.transactionNumber')}: </b>
              {this.state.gosReportDetails[gosReportFields.TRANSACTION_NUMBER]}
            </div>
            <div>
              <b>{t('gosReport.transactionStatus')}: </b>
              {t(
                'gosReport.transactionStatusValues.' +
                  this.state.gosReportDetails[
                    gosReportFields.TRANSACTION_STATUS
                  ]
              )}
            </div>
            <div>
              <b>{t('gosReport.deliveryPeriod')}: </b>
              {this.state.gosReportDetails[gosReportFields.DELIVERY_PERIOD]}
            </div>
            <div>
              <b>{t('gosReport.suppliedRenewableEnergy')}: </b>
              {t(
                'gosReport.suppliedEnergyType.' +
                  this.state.gosReportDetails[
                    gosReportFields.SUPPLIED_RENEWABLE_ENERGY
                  ]
              )}
            </div>
            <div>
              <b>{t('gosReport.biofuelType')}: </b>
              {t(
                'gosReport.fuelGeneration.' +
                  this.state.gosReportDetails[gosReportFields.BIOFUEL_TYPE]
              )}
            </div>
            <div>
              <b>{t('gosReport.naturalEnergyAmount')}: </b>
              {(
                this.state.gosReportDetails[
                  gosReportFields.NATURAL_ENERGY_AMOUNT
                ] || 0
              ).toFixed(1)}
            </div>
            <div>
              <b>{t('gosReport.calculatedMultiplier')}: </b>
              {
                this.state.gosReportDetails[
                  gosReportFields.CALCULATED_MULTIPLIER
                ]
              }
            </div>
            <div>
              <b>{t('gosReport.calculatedEnergyAmount')}: </b>
              {(
                this.state.gosReportDetails[
                  gosReportFields.CALCULATED_ENERGY_AMOUNT
                ] || 0
              ).toFixed(1)}
            </div>
          </Col>
          <Col span={colSpan}>
            <div>
              <b>{t('gosReport.ghgCapacity')}: </b>
              {(
                this.state.gosReportDetails[gosReportFields.GHG_CAPACITY] || 0
              ).toFixed(1)}
            </div>
            {(isB || isE || isH) && (
              <div>
                <b>{t('gosReport.consumptionPeriod')}: </b>
                {
                  this.state.gosReportDetails[
                    gosReportFields.CONSUMPTION_PERIOD
                  ]
                }
              </div>
            )}
            {(isB || isE || isH) && (
              <div>
                <b>{t('gosReport.consumptionProvider')}: </b>
                {
                  this.state.gosReportDetails[
                    gosReportFields.CONSUMPTION_PROVIDER
                  ]
                }
              </div>
            )}
            {(isB || isE || isH) && (
              <div>
                <b>{t('gosReport.consumptionProviderTransaction')}: </b>
                {
                  this.state.gosReportDetails[
                    gosReportFields.CONSUMPTION_PROVIDER_TRANSACTION_ID
                  ]
                }
              </div>
            )}
            {(isB || isH) && (
              <div>
                <b>{t('gosReport.productionPeriod')}: </b>
                {this.state.gosReportDetails[gosReportFields.PRODUCTION_PERIOD]}
              </div>
            )}
            {(isB || isH) && (
              <div>
                <b>{t('gosReport.eicwCode')}: </b>
                {this.state.gosReportDetails[gosReportFields.EICW_CODE]}
              </div>
            )}
            {isB && (
              <div>
                <b>{t('gosReport.posNumber')}: </b>
                {this.state.gosReportDetails[gosReportFields.POS_NUMBER]}
              </div>
            )}
            {isH && (
              <div>
                <b>{t('gosReport.rfnbo')}: </b>
                {t(
                  'gosReport.boolean.' +
                    this.state.gosReportDetails[gosReportFields.RFNBO]
                )}
              </div>
            )}
            {isH && (
              <div>
                <b>{t('gosReport.hydrogenTechnologyType')}: </b>
                {t(
                  'gosReport.hydrogenTechnologyTypeValues.' +
                    this.state.gosReportDetails[
                      gosReportFields.HYDROGEN_TECHNOLOGY_TYPE
                    ]
                )}
              </div>
            )}
            {isB && (
              <div>
                <b>{t('gosReport.energyType')}: </b>
                {t(
                  'gosReport.energyTypeValues.' +
                    this.state.gosReportDetails[gosReportFields.ENERGY_TYPE]
                )}
              </div>
            )}
            {isB && (
              <div>
                <b>{t('gosReport.biofuelComponentCn')}: </b>
                {
                  this.state.gosReportDetails[
                    gosReportFields.BIOFUEL_COMPONENT_CN
                  ]
                }
              </div>
            )}
          </Col>
          <Col span={colSpan}>
            {isB && (
              <div>
                <b>{t('gosReport.sustainableBiofuel')}: </b>
                {t(
                  'gosReport.boolean.' +
                    this.state.gosReportDetails[
                      gosReportFields.SUSTAINABLE_BIOFUEL
                    ]
                )}
              </div>
            )}
            {isB && (
              <div>
                <b>{t('gosReport.fuelType')}: </b>
                {translateFuelTypeClassificator(
                  this.state.gosReportDetails[gosReportFields.FUEL_TYPE],
                  'et'
                )}
              </div>
            )}
            {isB && (
              <div>
                <b>{t('gosReport.feedstock')}: </b>
                {translateFeedstockClassificator(
                  this.state.gosReportDetails[gosReportFields.FEEDSTOCK],
                  this.state.gosReportDetails
                )}
              </div>
            )}
            {isB && (
              <div>
                <b>{t('gosReport.productionPathway')}: </b>
                {translateProductionPathwayClassificator(
                  this.state.gosReportDetails[
                    gosReportFields.PRODUCTION_PATHWAY
                  ],
                  this.state.gosReportDetails
                )}
              </div>
            )}
            {(isB || isH) && (
              <div>
                <b>{t('gosReport.amount')}: </b>
                {this.state.gosReportDetails[gosReportFields.AMOUNT]}
              </div>
            )}
            {(isB || isH) && (
              <div>
                <b>{t('gosReport.lowerCalorificValueKg')}: </b>
                {(
                  this.state.gosReportDetails[
                    gosReportFields.LOWER_CALORIFIC_VALUE_KG
                  ] || 0
                ).toFixed(1)}
              </div>
            )}
            {(isB || isH) && (
              <div>
                <b>{t('gosReport.higherCalorificValueKg')}: </b>
                {(
                  this.state.gosReportDetails[
                    gosReportFields.HIGHER_CALORIFIC_VALUE_KG
                  ] || 0
                ).toFixed(1)}
              </div>
            )}
            {isB && (
              <div>
                <b>{t('gosReport.landUseCategory')}: </b>
                {t(
                  'goReport.estonianTranslations.landUseCategory.' +
                    this.state.gosReportDetails[
                      gosReportFields.LAND_USE_CATEGORY
                    ]
                )}
              </div>
            )}
            {isB && (
              <div>
                <b>{t('gosReport.landUseEmissions')}: </b>
                {
                  this.state.gosReportDetails[
                    gosReportFields.LAND_USE_EMISSIONS
                  ]
                }
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  getReportColumns = () => {
    const { t } = this.props;

    return [
      {
        title: t('gosReport.transactionDate'),
        dataIndex: gosReportFields.TRANSACTION_DATE,
      },
      {
        title: t('gosReport.transactionNumber'),
        dataIndex: gosReportFields.TRANSACTION_NUMBER,
      },
      {
        title: t('gosReport.suppliedRenewableEnergy'),
        dataIndex: gosReportFields.SUPPLIED_RENEWABLE_ENERGY,
        render: value => (
          <div>{t('gosReport.suppliedEnergyType.' + value)}</div>
        ),
      },
      {
        title: t('gosReport.biofuelType'),
        dataIndex: gosReportFields.BIOFUEL_TYPE,
        render: value => <div>{t('gosReport.fuelGeneration.' + value)}</div>,
      },
      {
        title: t('gosReport.naturalEnergyAmount'),
        dataIndex: gosReportFields.NATURAL_ENERGY_AMOUNT,
        render: value => (value || 0).toFixed(1),
      },
      {
        title: t('gosReport.calculatedMultiplier'),
        dataIndex: gosReportFields.CALCULATED_MULTIPLIER,
      },
      {
        title: t('gosReport.calculatedEnergyAmount'),
        dataIndex: gosReportFields.CALCULATED_ENERGY_AMOUNT,
        render: value => (value || 0).toFixed(1),
      },
      {
        title: t('gosReport.ghgCapacity'),
        dataIndex: gosReportFields.GHG_CAPACITY,
        render: value => (value || 0).toFixed(1),
      },
      {
        title: t('gosReport.deliveryPeriod'),
        dataIndex: gosReportFields.DELIVERY_PERIOD,
      },
      {
        dataIndex: gosReportFields.ID,
        render: (
          value,
          row // TODO: this
        ) => (
          <TableRowActions>
            <Button
              shape="circle"
              icon="select"
              size="small"
              type="primary"
              title={t('table.viewBtn')}
              onClick={() => this.handleShowDetailsModal(row)}
            />
          </TableRowActions>
        ),
      },
    ];
  };

  onPageChange = page => {
    return this.handleGenerate(page);
  };

  // ugly workaround for empty formValues in componentDidMount
  waitInit() {
    if (!this.props.formValues) {
      setTimeout(() => {
        this.waitInit();
      }, 100);
    } else {
      this.handleGenerate(0);
    }
  }

  componentDidMount() {
    this.waitInit();
  }

  componentWillUnmount() {
    this.props.resetGosRenewableReport();
  }

  render() {
    const { t, isUserAdmin } = this.props;
    const { yearFilter } = this.state;

    const { gosReport } = this.props;
    const { data, page, totalElements, pageSize } = gosReport.toJSON();

    return (
      <div>
        <Spin spinning={this.state.loading}>
          <form>
            <Row gutter={32}>
              <Col span={8}>
                <Radio.Group
                  name="filterModeGroup"
                  value={yearFilter}
                  onChange={e => this.setState({ yearFilter: e.target.value })}
                >
                  <Radio value>{t('gosReport.filterModeGroup.yearMode')}</Radio>
                  <Radio value={false}>
                    {t('gosReport.filterModeGroup.rangeMode')}
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={8} className={'no-label-field'}>
                {this.renderFilterField()}
              </Col>
              {isUserAdmin && (
                <Col span={8}>
                  <div className={'field-with-offset'}>
                    <Field
                      label={t('gosReport.company')}
                      name={formGosReportFields.LEGAL_ENTITY_ID}
                      component={AutoCompleteProducersProviderField}
                      hasFeedback={false}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row gutter={32}>
              <Table
                scroll={{ x: 'auto' }}
                rowKey={gosReportFields.ID}
                columns={this.getReportColumns(t)}
                dataSource={data}
                bordered
                pagination={{
                  onChange: this.onPageChange,
                  current: page,
                  total: totalElements,
                  pageSize,
                  defaultCurrent: 1,
                }}
              />
            </Row>
            <FormActions>
              <Button onClick={this.handleClear}>
                {t('gosReport.emptyButton')}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  this.handleGenerate();
                }}
              >
                {t('gosReport.generate')}
              </Button>
              <a href={this.getGenerateXlsUrl()} rel="noopener noreferrer">
                <Button>{t('gosReport.generateXls')}</Button>
              </a>
            </FormActions>
          </form>
        </Spin>
        <Modal
          title={t('gosReport.reportDetailModalTitle')}
          visible={this.state.detailModalVisible}
          footer={false}
          width="1200px"
          onCancel={this.hideDetailModal}
        >
          {this.renderGosReportDetails()}
        </Modal>
      </div>
    );
  }
}

const calcInitialDates = () =>
  moment().month() === 0
    ? {
        [formGosReportFields.DATE_FROM]: moment()
          .subtract(1, 'years')
          .startOf('year')
          .format(FORMAT_DEFAULT_DATE),
        [formGosReportFields.DATE_TO]: moment()
          .subtract(1, 'years')
          .endOf('year')
          .format(FORMAT_DEFAULT_DATE),
        [formGosReportFields.PERIOD_YEAR]: new Date().getFullYear() - 1,
      }
    : {
        [formGosReportFields.DATE_FROM]: moment()
          .startOf('year')
          .format(FORMAT_DEFAULT_DATE),
        [formGosReportFields.DATE_TO]: moment().format(FORMAT_DEFAULT_DATE),
        [formGosReportFields.PERIOD_YEAR]: new Date().getFullYear(),
      };

const formValuesSelector = getFormValues(FORM_GOS_REPORT);

const mapStateToProps = state => ({
  isUserAdmin: isAdmin(getRole(state)),
  initialValues: calcInitialDates(),
  formValues: formValuesSelector(state),
  gosReport: getGosReport(state),
});

const mapDispatchToProps = {
  fetchReportGosItemsPage: actions.fetchReportGosItemsPage,
  resetGosRenewableReport: actions.gosRenewableReportResetMasterActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_GOS_REPORT,
  })(GosReport)
);
