import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { downloadFileByURLWithoutDownloadAttribute } from '../../utils/files';

class ReportFileUpload extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
  };

  handleClick = async () => {
    const { url } = this.props;
    downloadFileByURLWithoutDownloadAttribute(url);
  };

  render() {
    const { t } = this.props;

    return (
      <Button icon="download" onClick={this.handleClick}>
        {t('downloadReport')}
      </Button>
    );
  }
}

export default ReportFileUpload;
