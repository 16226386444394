import React, { Component } from 'react';
import {
  searchCompany,
  searchManufacturer,
} from '../../actions/actionHelpers/endpoints';
import { AutoCompleteSelect } from '../';
import { legalEntityFields } from '../../constants/legalEntity';
import { legalEntityStatus } from '../../constants/classificators';
import PropTypes from 'prop-types';
import fuelType from '../../constants/classificators/fuelType';

const defaultCompaniesFilter = item => {
  return (
    item[legalEntityFields.STATUS] === legalEntityStatus.ACTIVE &&
    (item[legalEntityFields.MANUFACTURER] ||
      item[legalEntityFields.HYDROGEN_MANUFACTURER])
  );
};

const getDataSource = (companiesFilter, manufacturerType) => async value => {
  let data;
  if (
    manufacturerType === fuelType.HYDROGEN ||
    manufacturerType === fuelType.BIOMETHANE
  ) {
    ({ data } = await searchManufacturer(value, manufacturerType));
  } else {
    ({ data } = await searchCompany(value));
  }

  return data.filter(companiesFilter).map(item => ({
    value: item.id,
    label: `${item.name}, ${item.registrationCode}`,
  }));
};

class AutoCompleteCompanies extends Component {
  static propTypes = {
    companiesFilter: PropTypes.func.isRequired,
  };

  static defaultProps = {
    companiesFilter: defaultCompaniesFilter,
  };

  render() {
    const { companiesFilter, isHydrogenManufacturerSearch } = this.props;
    let manufacturerType = null;
    if (isHydrogenManufacturerSearch !== undefined) {
      manufacturerType = isHydrogenManufacturerSearch
        ? fuelType.HYDROGEN
        : fuelType.BIOMETHANE;
    }
    return (
      <AutoCompleteSelect
        {...this.props}
        getDataSource={getDataSource(companiesFilter, manufacturerType)}
      />
    );
  }
}

export default AutoCompleteCompanies;
