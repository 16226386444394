import React from 'react';
import { Row, Col } from 'antd';

function TATransactionAmount({
  t,
  naturalAmount,
  accountingAmount,
  ghgAmount,
  cost,
}) {
  if (ghgAmount !== undefined) {
    return (
      <React.Fragment>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t('ghgAmount')}: </b>
            {ghgAmount}
          </Col>
        </Row>
        {cost && (
          <Row className="global-margin-bottom-10">
            <Col span={8}>
              <b>{t('gosSetInfo.cost')}: </b>
              {cost}
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Row className="global-margin-bottom-10">
        <Col span={8}>
          <b>{t('gos.valueMj')}: </b>
          {accountingAmount}
        </Col>
      </Row>
      <Row className="global-margin-bottom-10">
        <Col span={8}>
          <b>{t('gos.naturalValueMj')}: </b>
          {naturalAmount}
        </Col>
      </Row>
      {cost && (
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t('gosSetInfo.cost')}: </b>
            {cost}
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

export default TATransactionAmount;
