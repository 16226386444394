import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import * as actions from '../../actions/actionCreators/transactionViewActionCreators';
import { getRole } from '../../reducers/userReducer';
import {
  TransactionInformation,
  TransactionGO,
  TransactionParticipantInformation,
  TransactionHistory,
  TransactionConsumerInfo,
  TransactionConsumptionInfo,
  TableActions,
  ReportFileUpload,
} from '../../components';
import { transactionFields } from '../../constants/transaction';
import transactionStatus from '../../constants/classificators/transactionStatus';
import transactionType from '../../constants/classificators/transactionType';
import { Spin, Button, Popconfirm, notification, Input } from 'antd';
import { isEmpty } from 'lodash';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import {
  getTransaction,
  getTransactionData,
} from '../../reducers/transactionViewReducer';
import { goBack } from '../../utils/gotoLink';
import {
  isDisabledRecallButton,
  hasRecallButton,
  hasRecallGuideText,
} from '../../utils/transactionHelpers';

export class TransactionView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    transactionId: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchTransaction: PropTypes.func.isRequired,
    transactionData: PropTypesImmutable.map,
    recallTransaction: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      recallReason: null,
    };
  }

  componentDidMount = () => {
    const { transactionId, fetchTransaction } = this.props;
    fetchTransaction(transactionId);
  };

  onRecall = (id, recallReason) => {
    const { recallTransaction } = this.props;

    recallTransaction(id, recallReason);
  };

  onChangeRecallReason = e => {
    this.setState({ recallReason: e.target.value });
  };

  manualIssue = id => {
    const { manualIssueTransaction } = this.props;

    manualIssueTransaction(id);
  };

  isSenderShow = transactionDataJs =>
    transactionDataJs[transactionFields.TYPE] !== transactionType.EXPIRATION;

  isConsumerShow = transactionDataJs =>
    transactionDataJs[transactionFields.TYPE] === transactionType.CANCELLATION;

  hasManualIssueButton = transactionDataJs =>
    transactionDataJs[transactionFields.MANUAL_ISSUE] === true;

  isStatementButtonShow = transactionDataJs =>
    transactionDataJs[transactionFields.TYPE] ===
      transactionType.CANCELLATION &&
    (transactionDataJs[transactionFields.STATUS] ===
      transactionStatus.SUCCEEDED ||
      transactionDataJs[transactionFields.STATUS] === transactionStatus.LOCKED);

  isReceiverShow = transactionDataJs =>
    transactionDataJs[transactionFields.TYPE] !== transactionType.EXPIRATION &&
    transactionDataJs[transactionFields.TYPE] !== transactionType.CANCELLATION;

  isCommentShow = transactionDataJs =>
    transactionDataJs[transactionFields.STATUS] === transactionStatus.REVOKED;

  renderRecallPart = (role, transactionDataJs, recallReason) => {
    const { t } = this.props;

    if (
      hasRecallButton(
        role,
        transactionDataJs[transactionFields.STATUS],
        transactionDataJs[transactionFields.TYPE],
        transactionDataJs[transactionFields.CAN_RECALL]
      )
    ) {
      return (
        <React.Fragment>
          <h2>{t('subtitle.recall')}</h2>
          <Input
            className="global-margin-top-10"
            placeholder={t('recallReason')}
            required
            value={recallReason || ''}
            onChange={this.onChangeRecallReason}
          />
          <div className="global-margin-top-24">
            <Popconfirm
              title={t('popconfirm.title')}
              onConfirm={() =>
                this.onRecall(
                  transactionDataJs[transactionFields.ID],
                  recallReason
                )
              }
              okText={t('popconfirm.buttonYes')}
              cancelText={t('popconfirm.buttonNo')}
            >
              <Button
                disabled={
                  !recallReason ||
                  isDisabledRecallButton(
                    transactionDataJs[transactionFields.STATUS],
                    transactionDataJs[transactionFields.CAN_RECALL]
                  )
                }
                className="global-margin-left-10"
              >
                {t('buttonRecall')}
              </Button>
            </Popconfirm>
          </div>
        </React.Fragment>
      );
    }
    if (
      hasRecallGuideText(
        role,
        transactionDataJs[transactionFields.STATUS],
        transactionDataJs[transactionFields.TYPE]
      )
    ) {
      return (
        <React.Fragment>
          <h2>{t('subtitle.recall')}</h2>
          <p className="global-margin-top-10">{t('recallViaTS')}</p>
        </React.Fragment>
      );
    }
    return null;
  };

  render() {
    const {
      t,
      isLoading,
      transactionData,
      transactionId,
      role,
      accounts,
    } = this.props;
    const { recallReason } = this.state;
    const transactionDataJs = transactionData.toJS();

    if (isEmpty(transactionDataJs) || isLoading) {
      return <Spin spinning />;
    }

    return (
      <React.Fragment>
        <h2>{t('subtitle.transactionInfo')}</h2>
        <div className="global-margin-top-24">
          <TransactionInformation
            t={t}
            info={transactionDataJs}
            accounts={accounts}
          />
        </div>

        <div>
          <h2 className="global-margin-top-42">
            {t('subtitle.transactionGO')}
          </h2>
          <div className="global-margin-top-24">
            <TransactionGO t={t} info={transactionDataJs} />
          </div>
        </div>

        {this.isSenderShow(transactionDataJs) && (
          <React.Fragment>
            <h2 className="global-margin-top-42">{t('subtitle.senderInfo')}</h2>
            <div className="global-margin-top-24">
              <TransactionParticipantInformation
                t={t}
                info={transactionDataJs}
                isSender
              />
            </div>
          </React.Fragment>
        )}

        {this.isReceiverShow(transactionDataJs) && (
          <React.Fragment>
            <h2 className="global-margin-top-42">
              {t('subtitle.receiverInfo')}
            </h2>
            <div className="global-margin-top-24">
              <TransactionParticipantInformation
                t={t}
                info={transactionDataJs}
              />
            </div>
          </React.Fragment>
        )}

        {this.isCommentShow(transactionDataJs) && (
          <React.Fragment>
            <h2 className="global-margin-top-42">{t('subtitle.reason')}</h2>
            <div className="global-margin-top-24">
              <div className="global-html-wrapper">
                {transactionDataJs[transactionFields.COMMENT]}
              </div>
            </div>
          </React.Fragment>
        )}

        {this.isConsumerShow(transactionDataJs) && (
          <React.Fragment>
            <h2 className="global-margin-top-42">{t('subtitle.consumer')}</h2>
            <div className="global-margin-top-24">
              <TransactionConsumerInfo t={t} info={transactionDataJs} />
            </div>

            <h2 className="global-margin-top-42">
              {t('subtitle.consumption')}
            </h2>
            <div className="global-margin-top-24">
              <TransactionConsumptionInfo t={t} info={transactionDataJs} />
            </div>
          </React.Fragment>
        )}

        <div>
          <h2 className="global-margin-top-42">{t('subtitle.history')}</h2>
          <div className="global-margin-top-24">
            <TransactionHistory t={t} info={transactionDataJs} />
          </div>
        </div>

        <TableActions>
          {this.isStatementButtonShow(transactionDataJs) && (
            <ReportFileUpload
              t={t}
              url={`/api/v1/transaction/${transactionId}/cancellationStatement`}
            />
          )}
          <Button onClick={goBack} className="global-margin-left-10">
            {t('buttonBack')}
          </Button>
        </TableActions>
        {this.renderRecallPart(role, transactionDataJs, recallReason)}
        {this.hasManualIssueButton(transactionDataJs) && (
          <Popconfirm
            title={t('popconfirm.issueTitle')}
            onConfirm={() =>
              this.manualIssue(transactionDataJs[transactionFields.ID])
            }
            okText={t('popconfirm.buttonYes')}
            cancelText={t('popconfirm.buttonNo')}
          >
            <Button
              disabled={isDisabledRecallButton(
                transactionDataJs[transactionFields.STATUS],
                true
              )}
              className="global-margin-left-10"
            >
              {t('buttonIssue')}
            </Button>
          </Popconfirm>
        )}
      </React.Fragment>
    );
  }
}

const getLoading = getLoadingSelector(getTransaction);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  transactionData: getTransactionData(state),
  role: getRole(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  recallTransaction: async (id, recallReason) => {
    const result = await dispatch(actions.recallTransaction(id, recallReason));

    if (!result.error) {
      notification.info({
        message: ownProps.t('recallInfoTitle'),
        description: ownProps.t('recallInfoDescription'),
      });

      dispatch(actions.fetchTransaction(id));
    }
  },
  manualIssueTransaction: async id => {
    const result = await dispatch(actions.issueTransaction(id));

    if (!result.error) {
      notification.info({
        message: ownProps.t('recallInfoTitle'),
        description: ownProps.t('manualIssueDescription'),
      });

      dispatch(actions.fetchTransaction(id));
    }
  },
  fetchTransaction: id => {
    dispatch(actions.fetchTransaction(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionView);
