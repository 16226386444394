import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { Spin } from 'antd';
import {
  AutoCompleteGOSAccountsSelect,
  AutoCompleteLegalEntity,
  TradingAccountOverviewDetailsTable,
} from '../../components';
import React, { Component } from 'react';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import {
  getSelectedTransferRows,
  getSelectedTransferRowsData,
} from '../../reducers/transferringGOSCertificateReducer';
import * as selectors from '../../reducers/transferringGOSCertificateReducer';
import * as actions from '../../actions/actionCreators/gosCertificateActionCreators';
import { connect } from 'react-redux';
import {
  TYPE_B,
  TYPE_E,
  TYPE_H,
  TYPE_KHG,
  TYPE_S,
} from '../../constants/accountGOS';
import {
  searchProviderLegalEntityByName,
  searchTraderProviderLoadingOperatorLegalEntityByName,
} from '../../actions/actionHelpers/endpoints';
import { getUserLegalEntityId } from '../../reducers/userReducer';

class TradingAccountTransfer extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    selectReceiver: PropTypes.func.isRequired,
    tradingAccountTransferAction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    selectedReceiver: PropTypes.number,
    // accountId: PropTypes.string.isRequired,
    groupedGOSCertificates: PropTypesImmutable.list.isRequired,
    type: PropTypes.string.isRequired,
  };

  componentDidMount = () => {
    this.props.selectReceiver(null);
  };

  render() {
    const {
      isLoading,
      t,
      type,
      groupedGOSCertificates,
      selectedReceiver,
      selectReceiver,
      tradingAccountTransferAction,
      userLegalEntityId,
    } = this.props;

    if (!isLoading && groupedGOSCertificates.isEmpty()) {
      return t('emptyTransfer');
    }

    return (
      <Spin spinning={isLoading}>
        <h2>{t('receiver')}</h2>
        {(type === TYPE_B || type === TYPE_H || type === TYPE_E) && (
          <AutoCompleteGOSAccountsSelect
            value={selectedReceiver}
            onChange={selectReceiver}
            placeholder={t('chooseReceiver')}
          />
        )}
        {type === TYPE_S && (
          <AutoCompleteLegalEntity
            value={selectedReceiver}
            onChange={selectReceiver}
            searchByName={searchProviderLegalEntityByName}
            unacceptableValues={[userLegalEntityId.toString()]}
            placeholder={t('chooseReceiver')}
          />
        )}
        {type === TYPE_KHG && (
          <AutoCompleteLegalEntity
            value={selectedReceiver}
            onChange={selectReceiver}
            searchByName={searchTraderProviderLoadingOperatorLegalEntityByName}
            unacceptableValues={[userLegalEntityId.toString()]}
            placeholder={t('chooseReceiver')}
          />
        )}
        {selectedReceiver && (
          <React.Fragment>
            <h2 className="global-margin-top-24">{t(`define${type}Amount`)}</h2>
            <TradingAccountOverviewDetailsTable
              t={t}
              type={type}
              popconfirmTitle={t('popconfirmTitleTransfer')}
              okText={t('popconfirmButtonYes')}
              cancelText={t('popconfirmButtonNo')}
              dataList={groupedGOSCertificates.toJS()}
              performAction={rows =>
                tradingAccountTransferAction({
                  receiverAccountId:
                    type === TYPE_B || type === TYPE_H || type === TYPE_E
                      ? selectedReceiver
                      : null,
                  receiverLegalEntityId:
                    type === TYPE_S || type === TYPE_KHG
                      ? selectedReceiver
                      : null,
                  rows,
                })
              }
            />
          </React.Fragment>
        )}
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(
  getSelectedTransferRows,
  selectors.getTransferring
);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  groupedGOSCertificates: getSelectedTransferRowsData(state),
  selectedReceiver: selectors.getSelectedReceiver(state),
  userLegalEntityId: getUserLegalEntityId(state),
});

const mapDispatchToProps = {
  selectReceiver: actions.selectReceiver,
  tradingAccountTransferAction: actions.tradingAccountTransferAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  TradingAccountTransfer
);
