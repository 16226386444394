import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import {
  TextField,
  ClassificatorField,
  DateField,
  SelectField,
} from '../../atoms';
import { Row, Col } from 'antd';
import { gas, meteringPointFields } from '../../constants/meteringPoint';

const ConsumptionPoint = ({ t, isAdmin, isAvp }) => {
  const isAvpOrNotAdmin = isAvp || !isAdmin;
  return (
    <div>
      <h2>{t('info.title')}</h2>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.START_DATE}
            component={DateField}
            label={t('info.startDate')}
            required={!isAvp}
            disabled={isAvp}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.END_DATE}
            component={DateField}
            label={t('info.endDate')}
            disabled={isAvp}
          />
        </Col>
        <Col span={8}>
          <Field
            component={SelectField}
            name={meteringPointFields.GAS}
            required
            disabled
            label={t('info.gas.title')}
            options={[
              {
                value: gas.HYDROGEN,
                label: t('info.gas.hydrogen'),
              },
              {
                value: gas.BIOMETHANE,
                label: t('info.gas.biomethane'),
              },
              {
                value: gas.LIQUID_BIOMETHANE,
                label: t('info.gas.liquidBiomethane'),
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.CONSUMPTION_TYPE}
            type={ClassificatorField.types.consumptionUsage}
            component={ClassificatorField}
            label={t('info.consumptionType')}
            required
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.EICZ_CODE}
            label={t('info.eiczCode')}
            component={TextField}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.DATA_SOURCE}
            type={ClassificatorField.types.meteringPointDataSource}
            component={ClassificatorField}
            label={t('info.dataSource')}
            disabled
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.GRID_OPERATOR}
            label={t('info.gridOperator')}
            component={TextField}
            disabled
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.COUNTY}
            component={ClassificatorField}
            type={ClassificatorField.types.counties}
            label={t('info.county')}
            required={!isAvpOrNotAdmin}
            disabled={isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.CITY}
            component={TextField}
            disabled={isAvpOrNotAdmin}
            label={t('info.municipality')}
            required={!isAvpOrNotAdmin}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Field
            name={meteringPointFields.ADDRESS}
            component={TextField}
            label={t('info.address')}
            disabled
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.ZIP_CODE}
            component={TextField}
            label={t('info.zipCode')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
        <Col span={8}>
          <Field
            name={meteringPointFields.DISTRICT}
            component={TextField}
            label={t('info.district')}
            disabled={isAvpOrNotAdmin}
            required={!isAvpOrNotAdmin}
          />
        </Col>
      </Row>
    </div>
  );
};

ConsumptionPoint.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isAvp: PropTypes.bool.isRequired,
};

export default ConsumptionPoint;
